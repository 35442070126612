import type { FC } from "react"
import React from "react"
import type { StackProps } from "@chakra-ui/react"
import {
  Flex,
  Stack,
  Link,
  Box,
  Text,
  IconButton,
  Icon,
  Image,
} from "@chakra-ui/react"

import { EXTERNAL_ROUTES, ROUTES } from "common/constants/routes"
import Copyright from "ui/components/Copyright"
import DiscordIcon from "ui/components/icons/DiscordIcon"
import TwitterIcon from "ui/components/icons/TwitterIcon"
import GithubIcon from "ui/components/icons/GithubIcon"
import TallyBlack from "ui/components/icons/tally/TallyBlack"
import PrivacyPolicyBanner from "ui/components/PrivacyPolicyBanner"

const MainLayoutFooter: FC<{ isWhiteLabel?: boolean } & StackProps> = ({
  isWhiteLabel = false,
  ...stackProps
}) => {
  const BADGE_ID = "d2ff8b54-def5-489b-b043-2e87c170596d"
  const ALCHEMY_URL = `https://alchemyapi.io/?r=badge:${BADGE_ID}`
  const ALCHEMY_ANALYTICS_URL = `https://analytics.alchemyapi.io/analytics`

  function logBadgeClick() {
    fetch(`${ALCHEMY_ANALYTICS_URL}/rollups-badge-click`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // eslint-disable-next-line camelcase
        badge_id: BADGE_ID,
      }),
    })
    if (typeof window !== "undefined") {
      const newWindow = window.open(ALCHEMY_URL, "_blank")

      if (newWindow) {
        newWindow.focus()
      }
    }
  }

  if (isWhiteLabel) return null

  return (
    <>
      <Stack
        isInline
        align={{ base: "center", lg: "flex-start" }}
        as="footer"
        bg="white"
        borderTop="gray.dark"
        flexDir={{ base: "column", lg: "row" }}
        justify="space-between"
        mt={{ base: 0, lg: 20 }}
        px={{ base: 6, lg: 18 }}
        py={{ base: 8, lg: 10 }}
        spacing={{ base: 0, lg: 12 }}
        w="full"
        {...stackProps}
      >
        <Flex
          direction={{ base: "column", lg: "row" }}
          mx={{ base: 0, lg: 4 }}
          w={{ base: "full", lg: "auto" }}
        >
          <Box mr={{ base: 0, lg: 36 }}>
            <Link className="no-underline" href={ROUTES.explore()}>
              <Icon
                as={TallyBlack}
                h={{ base: 8, lg: 9 }}
                w={{ base: 20, lg: 24 }}
              />
            </Link>
            <Copyright mb={4} mt={{ base: 0, lg: 5 }} />
          </Box>
          <Flex direction="row">
            <Flex
              direction="column"
              minWidth={{ base: "auto", md: "250px" }}
              my={{ base: 4, lg: 2 }}
            >
              <Text fontWeight="bold" mb={3} textStyle="md">
                Resources
              </Text>
              <Stack spacing={2}>
                <Link isExternal href={EXTERNAL_ROUTES.tally.docs.index()}>
                  <Text color="gray.500" textStyle="body.bold.md">
                    Developer Documentation
                  </Text>
                </Link>
                <Link isExternal href={EXTERNAL_ROUTES.tally.wiki()}>
                  <Text color="gray.500" textStyle="body.bold.md">
                    Governance Wiki
                  </Text>
                </Link>
                <Link isExternal href={EXTERNAL_ROUTES.tally.mirror()}>
                  <Text color="gray.500" textStyle="body.bold.md">
                    Blog
                  </Text>
                </Link>
                <Link
                  isExternal
                  href={EXTERNAL_ROUTES.tally.docs.knowledgeBase()}
                  mx={0}
                  pl={0}
                >
                  <Text color="gray.500" textStyle="body.bold.md">
                    Knowledge Base
                  </Text>
                </Link>
                <Link
                  isExternal
                  href={EXTERNAL_ROUTES.tally.docs.statusPage()}
                  mx={0}
                  pl={0}
                >
                  <Text color="gray.500" textStyle="body.bold.md">
                    System Status
                  </Text>
                </Link>
              </Stack>
            </Flex>
            <Flex
              direction="column"
              minWidth={{ base: "auto", md: "250px" }}
              ml={{ base: 10, sm: 18 }}
              my={{ base: 4, lg: 2 }}
            >
              <Text fontWeight="bold" mb={3} textStyle="md">
                Company
              </Text>
              <Stack spacing={2}>
                <Link isExternal href={EXTERNAL_ROUTES.tally.careers()}>
                  <Text color="gray.500" textStyle="body.bold.md">
                    Jobs
                  </Text>
                </Link>
                <Link href={ROUTES.brandKit()}>
                  <Text color="gray.500" textStyle="body.bold.md">
                    Brand Kit
                  </Text>
                </Link>
                <Link isExternal href={EXTERNAL_ROUTES.tally.support()}>
                  <Text color="gray.500" textStyle="body.bold.md">
                    Support
                  </Text>
                </Link>
                <Link isExternal href={EXTERNAL_ROUTES.tally.terms()}>
                  <Text color="gray.500" textStyle="body.bold.md">
                    Terms of Service
                  </Text>
                </Link>
                <Link isExternal href={EXTERNAL_ROUTES.privacyPolicy()}>
                  <Text color="gray.500" textStyle="body.bold.md">
                    Privacy Policy
                  </Text>
                </Link>
                <PrivacyPolicyBanner />
              </Stack>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          align="flex-end"
          direction="column"
          my={{ base: 0, lg: 2 }}
          pt={{ base: 1, sm: 2 }}
          w="full"
        >
          <Text
            display={{ base: "none", sm: "block" }}
            fontWeight="bold"
            mb={3}
            textAlign="right"
            textStyle="md"
            w={{ base: "full", sm: "auto" }}
          >
            Join our Community!
          </Text>
          <Stack
            isInline
            as="address"
            justify={{ base: "flex-end", lg: "flex-end" }}
            w="full"
          >
            <Link
              isExternal
              className="no-underline"
              href={EXTERNAL_ROUTES.tally.discord()}
            >
              <IconButton
                aria-label="Join the Tally Discord server"
                bg="white"
                icon={<Icon as={DiscordIcon} h={5} w={5} />}
              />
            </Link>
            <Link
              isExternal
              className="no-underline"
              href={EXTERNAL_ROUTES.twitter.tally()}
            >
              <IconButton
                aria-label="Go to Twitter profile"
                bg="white"
                color="gray.400"
                icon={<Icon as={TwitterIcon} color="gray.800" h={5} w={5} />}
              />
            </Link>
            <Link
              isExternal
              className="no-underline"
              href={EXTERNAL_ROUTES.tally.github()}
            >
              <IconButton
                aria-label="Go to GitHub profile"
                bg="white"
                icon={<Icon as={GithubIcon} h={5} w={5} />}
              />
            </Link>
          </Stack>
          <Flex align="center" direction="column" mt={{ base: 4, lg: 8 }}>
            <Image
              alt="Alchemy Supercharged"
              height="38px"
              id="badge-button"
              src="https://static.alchemyapi.io/images/marketing/rollups-badge-dark-1.png"
              onClick={logBadgeClick}
            />
          </Flex>
        </Flex>
      </Stack>
    </>
  )
}

export default MainLayoutFooter
